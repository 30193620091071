.blog-highlights-section {
    padding: 40px;
    background-color: #f4f4f4;
    text-align: center;
    padding-top: 80px;
    margin-top: -50px;
}

.blog-highlights-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #00468c;
    font-family: 'YourPreferredFontFamily', sans-serif;
    /* Add font-family */
}

.blog-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.blog-post {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    transition: transform 0.3s ease;
    position: relative;
    overflow: hidden;
    /* Ensure overflow is hidden */
}

.blog-post:hover {
    transform: translateY(-10px);
}

.blog-thumbnail {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

.blog-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 260px;
    box-sizing: border-box;
}

.blog-content #blog-title {
    color: #00468c;
    margin-bottom: 0px;

}

.blog-title {
    font-size: 1.25rem;
    margin: 10px 0;
    color: #00468c;
    font-family: 'YourPreferredFontFamily', sans-serif;
    /* Add font-family */
}

.blog-excerpt {
    font-size: 1rem;
    color: #666;
    margin-bottom: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blog-link {
    display: inline-block;
    padding: 10px 15px;
    background-color: #00468c;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.blog-link:hover {
    background-color: #003366;
}

.blog-date {
    font-size: 0.875rem;
    color: #999;
    margin-top: 10px;
    font-family: 'YourPreferredFontFamily', sans-serif;
    /* Add font-family */
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .blog-grid {
        flex-direction: column;
        align-items: center;
    }

    .blog-post {
        width: 90%;
    }
}