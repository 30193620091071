/* src/styles/Team.css */

.team-section {
    padding: 60px;
    text-align: center;
    background-color: #f9f9f9;
}

.header-section {
    font-size: 2rem;
    color: #333;
    margin-bottom: 40px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.team-wrapper {
    position: relative;
    overflow: hidden;
    /* Hide any overflow to ensure only one member is visible */
    max-width: 100%;
    margin: 0 auto;
}

.team-slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.team-member {
    flex: 0 0 100%;
    box-sizing: border-box;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 100%;
    /* Ensure only one member is shown at a time */
    margin: 0;
    /* Remove margins to prevent partial visibility */
}

.team-member-photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}

.team-member-info {
    padding: 10px;
}

.team-member-info h3 {
    font-size: 1.25rem;
    color: #00468c;
    margin: 10px 0;
}

.team-member-title {
    font-size: 0.8rem;
    color: #777;
    margin-bottom: 15px;
    font-weight: 500;
}

.team-member-bio {
    font-size: 0.9rem;
    color: #555;
    line-height: 1.4;
}

.team-dots {
    margin-top: 20px;
    text-align: center;
}

.dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #ccc;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dot.active {
    background-color: #00468c;
}

@media screen and (max-width: 768px) {
    .team-member {
        flex: 0 0 100%;
    }
}

@media screen and (max-width: 480px) {
    .team-member {
        flex: 0 0 100%;
    }
}