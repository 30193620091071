/* src/styles/MissionVision.css */

.mission-vision {
    padding: 60px 20px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: 70vh;
    overflow: hidden;
}

/* Create a semi-transparent overlay for better text visibility */
.text-overlay-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 70, 140, 0.6);
    /* Base color with opacity */
    z-index: 1;
    /* Ensure overlay is above the background image */
}

/* Style the text */
#mis-vis {
    position: relative;
    color: #ebebeb;
    /* White text color for contrast */
    font-size: 2.5rem;
    /* Adjust size as needed */
    text-align: center;
    padding: 20px;
    z-index: 2;
    /* Ensure text is above the overlay */
    font-family: 'Arial', sans-serif;
    /* Modern font */
    font-weight: bold;
    /* Make the text bold for better visibility */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    /* Add shadow for extra contrast */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    #mis-vis {
        font-size: 1.8rem;
        /* Slightly smaller text on smaller screens */
    }
}


.mission-vision h2 {
    font-size: 2rem;
    color: #ffffff;
    margin-bottom: 40px;
    font-weight: 700;
    text-align: center;
    background: linear-gradient(135deg, #6fbeff, rgb(143, 0, 0), #0080ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index: 2;

}


/* .mission-span {
    background-color: #000000;
    opacity: 0.5;
    height: 50px;
    margin-bottom: 20px;
} */



.mission-vision-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    z-index: 2;
    position: relative;
}

.mission-vision .mission,
.mission-vision .vision {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    padding: 30px;
    width: 100%;
    max-width: 500px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
}

.mission-vision .mission:hover,
.mission-vision .vision:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.mission-vision .icon {
    font-size: 3rem;
    color: #00468c;
    flex-shrink: 0;
}

.mission-vision .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mission-vision .content h3 {
    font-size: 1.65rem;
    color: #00468c;
    margin: 0;
    font-weight: 600;
}

.mission-vision .content p {
    font-size: 1.125rem;
    color: #333;
    line-height: 1.6;
    margin: 0;
    text-align: justify;
    text-justify: inter-word;
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .mission-vision h2 {
        font-size: 2rem;
    }

    .mission-vision .mission,
    .mission-vision .vision {
        max-width: 90%;
        padding: 20px;
    }

    .mission-vision .content h3 {
        font-size: 1.5rem;
    }

    .mission-vision .content p {
        font-size: 1rem;
    }

    .mission-vision .icon {
        font-size: 2rem;
    }
}

/* Stack vertically on mobile */
@media (max-width: 600px) {
    .mission-vision-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mission-vision .mission,
    .mission-vision .vision {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
    }

    .mission-vision .vision {
        margin-left: 0;
    }

    .mission .icon,
    .vision .icon {
        font-size: 1.5rem;
    }
}