.Btn {
    width: 170px;
    height: 40px;
    border: none;
    border-radius: 10px;
    background: linear-gradient(to right, #77530a, #ffd277, #77530a, #77530a, #ffd277, #77530a);
    background-size: 250%;
    background-position: left;
    color: #ffd277;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 1s;
    overflow: hidden;
}

.Btn::before {
    position: absolute;
    content: "Explore Our Properties";
    color: #ffd277;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 97%;
    height: 90%;
    border-radius: 8px;
    transition-duration: 1s;
    background-color: rgba(0, 0, 0, 0.842);
    background-size: 200%;
}

.Btn:hover {
    background-position: right;
    transition-duration: 1s;
}

.Btn:hover::before {
    background-position: right;
    transition-duration: 1s;
}

.Btn:active {
    transform: scale(0.95);
}

@media screen and (max-width:600px) {
    .Btn {
        width: 190px;
    }
}

















.Btn2 {
    width: 170px;
    height: 40px;
    border: none;
    border-radius: 10px;
    background: linear-gradient(to right, #77530a, #ffd277, #77530a, #77530a, #ffd277, #77530a);
    background-size: 250%;
    background-position: left;
    color: #ffd277;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 1s;
    overflow: hidden;
}

.Btn2::before {
    position: absolute;
    content: "Send Message";
    color: #ffd277;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 97%;
    height: 90%;
    border-radius: 8px;
    transition-duration: 1s;
    background-color: rgba(0, 0, 0, 0.842);
    background-size: 200%;
}

.Btn2:hover {
    background-position: right;
    transition-duration: 1s;
}

.Btn2:hover::before {
    background-position: right;
    transition-duration: 1s;
}

.Btn2:active {
    transform: scale(0.95);
}