.footer {
    background-color: rgb(48, 47, 47);
    padding: 20px 0;
    text-align: center;
}

.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.icon {
    color: #fff;
    font-size: 24px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.icon:hover {
    transform: translateY(-10px);
}

.icon:active {
    transform: scale(0.9);
}

/* Floating animation */
@keyframes float {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-5px);
    }
}

.icon {
    animation: float 3s ease-in-out infinite;
}

/* Individual icon hover effects */
.facebook:hover {
    background-color: #3b5998;
    /* Facebook blue */
}

.twitter:hover {
    background-color: #1da1f2;
    /* Twitter blue */
}

.instagram:hover {
    background-color: #e4405f;
    /* Instagram gradient color */
}

.linkedin:hover {
    background-color: #000000;
    /* LinkedIn blue */
}

.telegram:hover {
    background-color: #0088cc;
    /* Telegram blue */
}


@media screen and (max-width:600px) {
    .icon {
        color: #fff;
        font-size: 20px;
        border-radius: 50%;
        width: 40px;
        height: 40px;

    }
}
