.loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    z-index: 999;
}

.waiting-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    /* Ensure it is on top of everything else */
}

.waiting-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #000;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}













.admin-nav-logo-logo {
    margin-bottom: 20px;
}

.blog-form-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 50px;
}

.admin-dashboard {
    display: flex;
    justify-content: center;
}

.blog-form,
.edit-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
    /* background: #f5f5f5; */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 2px 9px 8px rgba(0, 47, 255, 0.1);
}

.blog-form input,
.blog-form textarea,
.blog-form select,
.edit-form input,
.edit-form textarea,
.edit-form select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.blog-form button,
.edit-form button {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.blog-form button:hover,
.edit-form button:hover {
    background-color: #0056b3;
}

.blog-filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.blog-filters input,
.blog-filters select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.blog-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.blog-item {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-item h3 {
    margin: 0 0 10px;
}

.blog-item p {
    margin: 5px 0;
}

.blog-images img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
}

.blog-actions {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.blog-actions button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #007bff;
    transition: color 0.3s;
}

.blog-actions button:hover {
    color: #0056b3;
}

.blog-detail-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.blog-detail {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    max-width: 800px;
    width: 90%;
    height: 90vh;
    overflow: auto;
}

.close-detail-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #ff0000;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.close-detail-button:hover {
    background-color: #cc0000;
}

.blog-detail h2 {
    margin-top: 0;
}

.blog-date,
.blog-category,
.blog-description {
    margin: 10px 0;
}

.blog-slider {
    display: flex;
    overflow-x: scroll;
    gap: 10px;
    margin: 10px 0;
}

.slider-image {
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
}




.blog-detail-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    z-index: 1000;
}

.close-detail-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #e07676;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s;
}

.close-detail-button:hover {
    background-color: #f50000;
}

.blog-detail {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 900px;
    width: 100%;
    position: relative;
}

.blog-date {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.image-slider {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.slider-wrapper {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.slider-img {
    width: 100%;
    height: 50vh;
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.slider-img.active {
    display: block;
    opacity: 1;
}


.slider-prev,
.slider-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    padding: 12px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 24px;
    z-index: 1;
}

.slider-prev {
    left: 10px;
}

.slider-next {
    right: 10px;
}

.slider-dots {
    text-align: center;
    margin-top: 10px;
}

.slider-dots span {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #ddd;
    margin: 0 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.slider-dots .active {
    background: #007bff;
}

.blog-title {
    font-size: 24px;
    margin: 20px 0 10px;
}

.blog-category {
    font-size: 18px;
    color: #007bff;
    margin-bottom: 10px;
}

.blog-description {
    font-size: 16px;
    line-height: 1.6;
}







.blog-detail {
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
    background-color: white;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    z-index: 1000;
    overflow-y: auto;
    max-height: 90vh;
}

.close-detail {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.carousel {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel img {
    width: 100%;
    max-height: 400px;
    object-fit: fill;
    border-radius: 4px;
}

.carousel button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 4px;
    z-index: 1;
}

.carousel button:hover {
    background-color: rgba(255, 255, 255, 1);
}

.carousel button:nth-child(1) {
    left: 10px;
}

.carousel button:nth-child(3) {
    right: 10px;
}

.blog-date {
    text-align: right;
    font-size: 14px;
    color: #999;
    margin-top: 10px;
}

/* Overlay for the modal background */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    margin-top: 0px;
}



.posted-category-date {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.change-password {
    display: flex;
}


@media screen and (max-width:610px) {
    .posted-category-date {
        display: flex;
        flex-direction: column;
    }

}


@media screen and (max-width:440px) {

    .blog-filters {
        width: 100%;
    }

    .blog-filters input {
        width: 100%;
    }

    .blog-filters select {
        width: 40%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        font-size: 14px;
    }


}

@media screen and (max-width:440px) {
    .change-text {
        display: none;
    }

}