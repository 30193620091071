/* src/styles/AboutUs.css */

/* General Styles */
.about-us {
    font-family: 'Arial', sans-serif;
    color: #333;
}

/* Introduction */


.about-pages {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #ffffff, #e0eafc);
    padding: 40px;
    overflow: hidden;
}

.about-introduction {
    position: relative;
    max-width: 900px;
    text-align: center;
    z-index: 2;
    color: #003366;
    padding: 40px;
}

.content-wrapper {
    position: relative;
    z-index: 3;
}

.intro-title {
    font-size: 2rem;
    font-weight: 600;
    color: #00468c;
    margin-bottom: 20px;
    animation: fadeIn 1s ease-out;
}

.intro-text {
    font-size: 1.5rem;
    line-height: 1.6;
    color: #333;
    animation: fadeIn 1.5s ease-out;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Floating Bubble Animation */
.intro-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}

.floating-bubble {
    position: absolute;
    background: rgba(0, 102, 204, 0.2);
    border-radius: 50%;
    animation: float 8s ease-in-out infinite;
    opacity: 0.5;
}

.bubble1 {
    width: 150px;
    height: 150px;
    top: 10%;
    left: 20%;
    animation-delay: 0s;
}

.bubble2 {
    width: 200px;
    height: 200px;
    top: 50%;
    left: 70%;
    animation-delay: 2s;
}

.bubble3 {
    width: 120px;
    height: 120px;
    top: 80%;
    left: 30%;
    animation-delay: 4s;
}

@keyframes float {

    0%,
    100% {
        transform: translateY(0) scale(1);
    }

    50% {
        transform: translateY(-20px) scale(1.1);
    }
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .intro-title {
        font-size: 2rem;
    }

    .intro-text {
        font-size: 1.2rem;
    }
}



/* Company History */


.about-page {
    padding: 50px 20px;
    background: #f5f8fb;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
}

.company-history {
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 40px 20px;
    width: 100%;
    max-width: 900px;
    position: relative;
    overflow: hidden;
}

.company-history h2 {
    font-size: 2rem;
    color: #00468c;
    margin-bottom: 30px;
    font-weight: 700;
    position: relative;
    text-align: center;
}

.timeline {
    position: relative;
    padding: 20px 0;
    margin-top: 20px;
    margin-left: 20px;
    border-left: 3px solid #00468c;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.timeline-item {
    position: relative;
    padding-left: 60px;
    display: flex;
    align-items: flex-start;
    animation: fadeInUp 0.8s ease;
}

.timeline-icon {
    position: absolute;
    left: -30px;
    top: 5px;
    background: #00468c;
    color: #fff;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.timeline-content {
    background: #f0f4f8;
    border-radius: 8px;
    padding: 15px 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: transform 0.3s ease;
}

.timeline-content h3 {
    font-size: 1.5rem;
    color: #00468c;
    margin-bottom: 10px;
    font-weight: 600;
}

.timeline-content p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
}

.timeline-content:hover {
    transform: translateY(-5px);
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .company-history {
        padding: 20px 15px;
    }

    .company-history h2 {
        font-size: 2rem;
    }

    .timeline-content h3 {
        font-size: 1.25rem;
    }

    .timeline-content p {
        font-size: 0.95rem;
    }

    .timeline {
        margin-left: 10px;
        padding-left: 15px;
    }

    .timeline-item {
        padding-left: 50px;
    }
}


/* History and Milestones */
.milestones {
    padding: 40px 15px;
    background: #f4f4f4;
    text-align: center;
    border-radius: 8px;
    margin: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.milestones h2 {
    font-size: 2rem;
    color: #00468c;
    margin-bottom: 15px;
    font-weight: 700;
}

.milestones p {
    font-size: 0.875rem;
    color: #666;
    margin: 0 auto;
    max-width: 90%;
    line-height: 1.6;
}

/* Team Members */

/* src/styles/Team.css */

.team-members {
    padding: 60px 20px;
    background-color: #f4f4f4;
    text-align: center;
}

.team-members h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 40px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.team-members-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}

.team-member {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
}

.team-member:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.team-member-photo {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.team-member-info {
    padding: 20px;
}

.team-member-info h3 {
    font-size: 1.5rem;
    color: #00468c;
    margin: 10px 0;
}

.team-member-title {
    font-size: 1rem;
    color: #777;
    margin-bottom: 15px;
    font-weight: 500;
    text-transform: uppercase;
}

.team-member-bio {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .team-member {
        margin-bottom: 20px;
    }

    .team-member-info h3 {
        font-size: 1.25rem;
    }

    .team-member-title,
    .team-member-bio {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .team-member-photo {
        height: 250px;
    }

    .team-member-info {
        padding: 15px;
    }

}


/* Awards and Recognitions */
.awards-recognitions {
    padding: 40px 15px;
    background: #fff;
    text-align: center;
    border-radius: 8px;
    margin: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.awards-recognitions h2 {
    font-size: 2rem;
    color: #00468c;
    margin-bottom: 15px;
    font-weight: 700;
}

.awards-recognitions p {
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 10px;
}

.awards-recognitions ul {
    list-style-type: disc;
    padding-left: 20px;
    margin: 0 auto;
    max-width: 90%;
    text-align: left;
}

/* Call to Action */
.cta {
    padding: 40px 15px;
    background: #00468c;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    margin: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cta h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    font-weight: 700;
}

.cta p {
    font-size: 1rem;
    margin-bottom: 15px;
}

.cta-button {
    display: inline-block;
    padding: 12px 20px;
    background: #fff;
    color: #00468c;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
    background-color: #e0e0e0;
    transform: scale(1.05);
}





/* ********************************* */
/* src/styles/AboutUs.css */

/* Mission and Vision */