/* BlogDetail.css */

#blog-detail {
    position: relative;
    width: 100%;
}

.image-slider {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.slider-container {
    display: flex;
    align-items: center;
}

.slider-images {
    display: flex;
    transition: transform 0.5s ease;
}

.slider-img {
    width: 100%;
    flex-shrink: 0;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
}

.arrow.left {
    left: 0;
}

.arrow.right {
    right: 0;
}

.dots {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: gray;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
}

.dot.active {
    background-color: black;
}



#blog-detail {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.back-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: #333;
    text-decoration: none;

}

.back {
    margin-left: 8px;
    font-size: 16px;
    color: #007bff;
}

.bedroom-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.bedroom-button {
    padding: 10px 20px;
    margin: 0 5px;
    background-color: #f5f5f5;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.bedroom-button.active {
    background-color: #007bff;
    color: #fff;
}

.bedroom-button:hover {
    background-color: #007bff;
    color: #fff;
}

.blogs-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.blog-card {
    display: flex;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s;
}

.blog-card:hover {
    transform: translateY(-5px);
}

.blog-image-container {
    width: 40%;
    position: relative;
}

.image-wrapper {
    position: relative;
}

.blog-img {
    width: 100%;
    height: auto;
    object-fit: cover;
    cursor: pointer;
}

.view-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
}

.blog-info {
    padding: 15px;
    width: 60%;
}

.blog-title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
}

.blog-categories,
.blog-date {
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
}

.blog-description {
    font-size: 16px;
    line-height: 1.5;
}

.no-apartment-message {
    text-align: center;
    font-size: 18px;
    color: #999;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-width: 90%;
    max-height: 80%;
    overflow-y: auto;
}

.modal-img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}





.blog-cardss {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-cardss:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blog-image-container {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    /* Maintains a 16:9 aspect ratio */
    overflow: hidden;
    border-bottom: 2px solid #eee;
}

.image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.blog-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.blog-img:hover {
    transform: scale(1.1);
}

.view-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.view-icon:hover {
    background: rgba(0, 0, 0, 0.8);
}

.blog-info {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.blog-title {
    font-weight: 700;
    color: #333;
    margin-bottom: 5px;
}

.blog-categories {
    font-size: 0.9rem;
    color: #666;
}

.blog-description {
    font-size: 1rem;
    color: #444;
    line-height: 1.6;
    overflow: hidden;
}


@media (min-width: 768px) {


    .blog-cardss {
        flex-direction: row;
        align-items: center;
    }

    .blog-image-container {
        width: 40%;
        aspect-ratio: 4/3;
    }

    .blog-info {
        width: 60%;
        padding: 20px;
    }

    .blog-description {
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

@media (max-width: 767px) {
    .blog-cardss {
        flex-direction: column;
    }

    .blog-image-container {
        width: 100%;
        aspect-ratio: 16/9;
    }

    .blog-info {
        padding: 15px;
    }

    #title {
        font-size: 19px;
    }
}







/* BlogDetail.css */
.gallery-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure it's on top of other content */
    cursor: pointer;
    /* Show pointer cursor to indicate clickable area */
}

.gallery-overlay .image-gallery {
    max-width: 90%;
    max-height: 90%;
    cursor: default;
    /* Show default cursor inside the gallery */
}

.image-gallery-slide {
    cursor: default;
    /* Ensure default cursor inside slides */
}

.image-gallery-icon {
    cursor: pointer;
    /* Ensure icons are clickable */
}

.back {
    font-size: 0.8rem;
    padding-bottom: 5px;

}