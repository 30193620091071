/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;500&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
    --header-height: 2.5rem;

    /*========== Colors ==========*/
    /*Color mode HSL(hue, saturation, lightness)*/
    --white-color: hsl(0, 0%, 100%);
    --black-color: hsl(0, 0%, 0%);
    --blue: rgb(0, 63, 92)
        /*========== Font and typography ==========*/
        /*.5rem = 8px | 1rem = 16px ...*/
        --body-font: "Montserrat Alternates", sans-serif;
    --h1-font-size: 1.5rem;
    --normal-font-size: 0.938rem;

    /*========== Font weight ==========*/
    --font-regular: 400;
    --font-medium: 500;

    /*========== z index ==========*/
    --z-tooltip: 10;
    --z-fixed: 100;
}

/*========== Responsive typography ==========*/
@media screen and (min-width: 1150px) {
    :root {
        --normal-font-size: 1rem;
    }
}

/*=============== BASE ===============*/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: var(--header-height) 0 0 0;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    background-color: var(--white-color);
    overflow-x: hidden;
    /* Prevent horizontal overflow */
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.container {
    max-width: 1120px;
    margin-inline: 1.5rem;
}

/*=============== HEADER & NAV ===============*/
.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    /* Initial transparent background */
    transition: background-color 0.3s ease-in-out;
    /* Smooth transition for background change */
    z-index: 1000;
    border: none;
    /* Ensure the header is on top */
}

.scroll-header {
    background-color: rgba(0, 0, 0, 0.5);
    /* Black background with some opacity */
}

/* **************************** */

/* #color-navlist {
    color: white;
} */

/* **************************** */

.nav {
    position: relative;
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.nav__logo {
    color: var(--white-color);
    font-weight: var(--font-medium);
    width: 170px;
}

.nav__close,
.nav__toggle {
    display: flex;
    color: var(--white-color);
    font-size: 1.5rem;
    cursor: pointer;
}

.nav__item a span {
    color: black;
    font-weight: 600;
}

.nav__item a span:hover {
    color: rgb(104, 0, 0);
}


/* *** logo ** */



.nav__logo {
    display: flex;
    align-items: flex-end;
    text-decoration: none;
    margin-left: 20px;
    /* Remove underline from link */
}

.logo-logo {
    max-height: 60px;
    margin-right: 10px;
    /* Space between image and text */
}

.logo-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.logo-title {
    font-size: 1.3rem;
    /* Size for "Dream" */
    font-weight: bold;
    color: #00468c;
    /* Adjust color as needed */
    margin: 0;
    margin-bottom: -5px;
}

.logo-subtitle {
    font-size: 1rem;
    /* Size for "Real Estate" */
    color: #666;
    /* Adjust color as needed */
    margin: 0;
    display: block;
    /* Ensures the subtitle is on a new line */
}

.nav__logo:hover .logo-title,
.nav__logo:hover .logo-subtitle {
    color: #003366;
    /* Change color on hover */
    transition: color 0.3s ease;
    /* Smooth color transition */
}

/* Mobile Responsive Styles */
@media (max-width: 808px) {
    .logo-title {
        font-size: 1rem;
        /* Slightly smaller size for mobile */
    }

    .logo-subtitle {
        font-size: 0.875rem;
        /* Smaller size for mobile */
    }

    .nav__logo {
        margin-left: 10px;
        /* Remove underline from link */
    }
}


/* ********* */




/* Navigation for mobile devices */
@media screen and (max-width: 785px) {

    .header {
        height: 3.5rem;
        padding: 5px;
    }

    .logo-logo {
        height: 50px;
    }

    .nav__menu {
        position: fixed;
        left: -100%;
        top: 0;
        background-color: rgb(0, 63, 92);
        width: 100%;
        height: 100%;
        padding: 6rem 3.5rem 4.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: left 0.4s;
    }

    .nav__item {
        transform: translateX(-150px);
        visibility: hidden;
        transition: transform 0.4s ease-out, visibility 0.4s;
    }

    .nav__item a span {
        color: rgb(255, 255, 255);
    }


    .nav__item:nth-child(1) {
        transition-delay: 0.1s;
    }

    .nav__item:nth-child(2) {
        transition-delay: 0.2s;
    }

    .nav__item:nth-child(3) {
        transition-delay: 0.3s;
    }

    .nav__item:nth-child(4) {
        transition-delay: 0.4s;
    }

    .nav__item:nth-child(5) {
        transition-delay: 0.5s;
    }

    .nav__toggle {
        display: flex;
        color: var(--black-color);
        margin-right: 40px;
        /* Ensure toggle is visible */
    }

    .nav__logo {
        width: 150px;

    }

    .nav__item a span:hover {
        color: rgb(179, 176, 176);
    }


}


@media screen and (max-width: 350px) {
    .nav {
        margin: 0 10px 0 10px;
    }
}

.nav__list {
    display: flex;
}

.nav__list {
    flex-direction: column;
    row-gap: 2rem;
}

.nav__link {
    position: relative;
    color: rgb(191, 191, 191);
    font-size: var(--h1-font-size);
    font-weight: var(--font-medium);
    display: flex;
    align-items: center;
    transition: opacity 0.4s;
    justify-content: center;
}

.nav__close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
}

/* Show menu */
.show-menu {
    left: 0;
}

/* Animation link when displaying menu */
.show-menu .nav__item {
    visibility: visible;
    transform: translateX(0);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */


@media screen and (max-width: 1150px) {
    .nav {
        margin: 0 20px 0 20px;
    }
}

.section {
    padding: 4.5rem 0 2rem;
}

.section__title {
    font-size: var(--h1-font-size);
    color: var(--title-color);
    text-align: center;
    margin-bottom: 1.5rem;
}

.section__height {
    height: 100vh;
}

.active-link {
    color: white;
}

.active-link::before {
    content: "";
    position: absolute;
    bottom: -0.5rem;
    width: 35px;
    height: 2px;
    background-color: rgb(120, 71, 233);
    border-radius: 3px;
    transition: width 0.4s ease-out;
}

@media screen and (min-width: 785px) {
    .container {
        margin-inline: auto;
    }

    .nav {
        height: calc(var(--header-height) + 2rem);
    }

    .nav__toggle,
    .nav__close {
        display: none;
    }

    .nav__link {
        font-size: var(--normal-font-size);
    }

    .nav__link i {
        font-size: 1.5rem;
    }

    .nav__list {
        flex-direction: row;
        column-gap: 2.5rem;
    }

    .nav__menu {
        display: flex;
        align-items: center;
        column-gap: 3.5rem;
        margin-right: 50px;
    }
}


@media screen and (max-width: 800px) {
    .nav__link {
        font-size: 1rem;
    }
}


/* Existing styles... */

/*=============== Navigation Links ===============*/
.nav__link {
    position: relative;
    color: rgb(191, 191, 191);
    display: flex;
    align-items: center;
    transition: opacity 0.4s;
    justify-content: center;
    font-family: var(--body-font);
    /* Ensure consistent font family */
}

.nav__link::before {
    content: "";
    position: absolute;
    bottom: -0.5rem;
    width: 35px;
    height: 2px;
    background-color: rgb(76, 0, 255);
    /* Blue color */
    border-radius: 3px;
    transition: width 0.4s ease-out, background-color 0.4s ease-out;
    transform: scaleX(0);
    /* Start with scaleX(0) for the hover effect */
    transform-origin: bottom left;
    /* Transform from the left side */
}

.nav__link:hover::before,
.nav__link.active-link::before {
    transform: scaleX(1);
    /* Scale to full width */
    background-color: rgb(76, 0, 255);
    /* Ensure the background color is consistent */
}

/* Existing styles for mobile and other devices... */