/* Container for all services */
.services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

.service-upper {
    background-color: rgb(252, 254, 255);
}

#service-upper {
    background-color: rgb(252, 254, 255);
}

.intro-content {
    margin-top: 30px;
}

#intro-content-title {
    font-size: 2.3rem;
    font-weight: 600;
    color: #003366;
}

/* Individual service card */
.service {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 250px;
    margin: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;

}

/* .service:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

#service-up :hover {
    transform: translateY(-5px);
    transition: 0.5s;
} */

.service-image {
    width: 100%;
    height: 240px;
    /* Adjust the height as needed */
    object-fit: cover;
}

.service-content {
    padding: 20px;
    /* text-align: center; */
}

#service-up:hover {
    transform: scale(0.97);
}

.service-content h3 {
    margin-top: 15px;
    font-size: 1.2rem;
    font-weight: 600;
}

.service-content p {
    margin: 10px 0;
    font-size: 1rem;
}

.service-cta {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #00468c;
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
}

.service-cta:hover {
    background-color: #003366;
}

.service:hover {
    transform: translateY(-10px);
}

/* *********************************************** */

.services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


/*  ************************************************ */

/* Introduction Text Container */
.introduction-text {
    background: rgb(255, 255, 255);
    color: #3d3d3d;
    padding: 60px 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
}

/* Main Heading */
.introduction-text h1 {
    font-size: 2.8rem;
    margin-bottom: 20px;
    font-weight: 700;
    animation: fadeInUp 1s ease-out;
    letter-spacing: 1px;
}

/* Paragraph Text */
.introduction-text p {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 30px;
    animation: fadeInUp 1s ease-out 0.5s;
    /* Delayed animation for paragraph */
    opacity: 1;
}

/* Keyframes for Fade In Up Animation */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
    .introduction-text {
        padding: 40px 15px;
    }

    .introduction-text h1 {
        font-size: 2.2rem;
    }

    .introduction-text p {
        font-size: 1rem;
    }
}

/* Decorative Effects */
.introduction-text::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: pulse 10s infinite;
    z-index: 0;
    /* Decorative pulse effect */
}

/* Keyframes for Pulse Effect */
@keyframes pulse {
    0% {
        transform: scale(0.5);
        opacity: 0.3;
    }

    50% {
        transform: scale(1);
        opacity: 0.1;
    }

    100% {
        transform: scale(0.5);
        opacity: 0.3;
    }
}

/* Ensure text is above the pulse effect */
.introduction-text h1,
.introduction-text p {
    position: relative;
    z-index: 1;
}


/* Responsive design */
@media screen and (max-width: 768px) {
    .services-container {
        flex-direction: column;
        align-items: center;
    }

    .service {
        width: 90%;
        /* Full width minus some padding */
    }

    #intro-content-title {
        font-size: 1.7rem;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .service {
        width: 45%;
        /* Two cards per row */
    }
}

@media screen and (min-width: 1025px) {
    .service {
        width: 30%;
        /* Three cards per row */
    }
}