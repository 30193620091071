.phone-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #3b5998;
    /* Green background */
    padding: 15px;
    border-radius: 50%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 1000;
    /* Ensure it stays on top */
    transition: transform 0.3s ease;
}

.phone-icon:hover {
    transform: scale(1.1);
}

.phone-icon:active {
    transform: scale(0.9);
}


.icon-phone-only {
    font-size: 30px;
    animation: vibrate 1s infinite;
}


@keyframes vibrate {

    0%,
    100% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(-10deg);
    }

    50% {
        transform: rotate(10deg);
    }

    75% {
        transform: rotate(-10deg);
    }
}



@media (max-width: 768px) {
    .phone-icon {
        padding: 10px;
    }
}

@media (max-width: 568px) {
    .icon-phone-only {
        font-size: 25px;
        animation: vibrate 1s infinite;
    }
}