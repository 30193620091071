/*=============== HERO SECTION ===============*/
.hero-section {
    margin-top: -50px;
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    overflow: hidden;
}

/* Slider Container */
.hero-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    /* Ensure content is above slider */
}

/* Slider Images Wrapper */
.hero-slider-images {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 1.5s ease-in-out;
}

/* Slider Images */
.hero-slider-image {
    min-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/* Content Styling */
.hero-content {
    position: relative;
    max-width: 90%;
    /* Adjusted to fit smaller screens */
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    /* Slightly darker for better readability */
    border-radius: 12px;
    /* Slightly rounded corners for a modern look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    /* Subtle shadow for depth */
    backdrop-filter: blur(2px);
    /* Adding a backdrop blur effect */
    transition: background-color 0.3s ease, padding 0.3s ease;
}

.hero-headline {
    font-size: 2.5rem;
    font-weight: 700;
    /* Bold for better emphasis */
    margin-bottom: 1rem;
    line-height: 1.2;
    color: #ffffff;
    /* Improved line spacing */
}

.hero-subheadline {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    line-height: 1.5;
    /* Improved line spacing */
}

.hero-cta {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.cta-button {
    background-color: #00468c;
    color: #ffffff;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 6px;
    /* Slightly more rounded for modern look */
    transition: background-color 0.3s ease, transform 0.2s ease;
    cursor: pointer;
    text-align: center;
    display: inline-block;
}

.cta-button:hover {
    background-color: #003366;
    transform: scale(1.05);
    /* Slight zoom effect on hover */
}

@media screen and (max-width: 1024px) {
    .hero-content {
        max-width: 95%;
        /* Adjusted width for tablets */
        padding: 25px;
    }

    .hero-headline {
        font-size: 2.2rem;
    }

    .hero-subheadline {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 768px) {


    .hero-content {
        max-width: 90%;
        /* Full width on smaller screens */
        padding: 20px;
    }

    .hero-headline {
        font-size: 1.3rem;
        /* Smaller font size for smaller screens */
        margin-bottom: 0.75rem;
    }

    .hero-subheadline {
        font-size: 0.9rem;
        /* Smaller font size for better readability */
        margin-bottom: 1.5rem;
    }

    .hero-cta {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        /* Stack buttons vertically */
        gap: 0.5rem;
    }

    .cta-button {
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 480px) {
    .hero-headline {
        font-size: 1.5rem;
        /* Further reduced font size for very small screens */
    }

    .hero-subheadline {
        font-size: 0.9rem;
        /* Further reduced font size */
    }
}