.con {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 150px;
    margin-bottom: 100px;

}

.contact-us-container {
    margin-top: 0;
}

.cta #cta-h2 {
    font-size: 2rem;
}

.con h2 {
    margin-bottom: 100px;
    font-size: 30px;
    color: rgb(0, 63, 92);
    text-shadow: 1px 1px 1px black;
    font-weight: 600;
    text-transform: uppercase;
}

.ab-line {
    /* border: 1px solid rgb(233, 227, 216); */
    margin-left: -120px;
    font-weight: 100;
}

.contact-us {
    margin-left: 9%;
    margin-right: 9%;
    margin-top: 0px;
}

.contentss {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}

.contentss h3 {
    font-size: 25px;
    font-weight: 500;
    color: rgb(80, 80, 80);
    margin-bottom: 20px;
}

.k {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ks span {
    color: rgb(0, 63, 92);
    font-size: larger;
}

.address {
    border-right: 2px solid black;
    padding-right: 10px;
}

.phone-number {
    border-right: 2px solid black;
}

.address span {
    font-size: 18px;
}

.inputss {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
}

.upper {
    display: flex;
    width: 100%;
}

.lower {
    display: flex;
    flex-direction: column;
}

.inp {
    margin-top: 10px;
}

.inpu {
    width: 100%;
}

.j {
    margin-right: 5px;
}

.inp-low {
    margin-bottom: 10px;
}

input {
    outline: none;
    padding: 15px 20px;
    font-size: 18px;
}

input::placeholder {
    font-size: 17px;
}

.message {
    padding: 15px 20px;
    outline: none;
    min-height: 100px;
    resize: vertical;
}

.message::placeholder {
    font-size: 19px;
}

.buttons {
    margin-top: 30px;
    padding: 20px 15px;
}

.butt {
    padding: 15px 30px;
    border-radius: 20px;
    cursor: pointer;

}

.butt:hover {
    background-color: orangered;
    transition-duration: 1s;
}

.map-width {
    width: 100%;
    z-index: -1000;
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.header-section {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-bottom: 21px;
    font-weight: 700;
    color: rgb(16, 17, 94);
}

#contact-headers {
    color: rgb(0, 51, 99);
    font-weight: 600;
}


.Send-button {
    border: 0px;
}

.gradient-text {
    font-size: 48px;
    font-weight: bold;
    /* Define the gradient as the background */
    /* background: linear-gradient(to right, #0c0b09, #000000, #000000, #000000, #000000, #ffffff); */
    /* background: linear-gradient(to right, #77530a, #ffd277, #77530a, #77530a, #ffd277, #77530a); */
    /* Use the gradient background for text */
    /* -webkit-background-clip: text; */
    /* For Webkit-based browsers */
    /* background-clip: text; */
    /* Only show the background for text */
    /* -webkit-text-fill-color: transparent; */
    /* Makes the text transparent to show the background */
    /* color: blue; */
}

.fontsize-small {
    font-size: 17px;

}

@media screen and (max-width:930px) {
    .contentss {
        display: flex;
        flex-direction: column;
    }

    .address,
    .phone-number {
        border: none;
    }

    .k {
        margin-top: 50px;
    }
}

@media screen and (max-width:670px) {
    .contact-section {
        display: flex;
        flex-direction: column;
    }

    .contact-container {
        display: flex;
        justify-content: space-between;
    }


    .cta #cta-h2 {
        font-size: 1.6rem;
    }

    .upper {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .j {
        margin-right: 0px;
    }

    .inpu {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

@media screen and (max-width:430px) {
    .gradient-text {
        font-size: 48px;
        font-weight: bold;
    }

    .cta #cta-h2 {
        font-size: 1.5rem;
    }

    .contact-container {
        display: flex;
        flex-direction: column;
    }

}