.navbar-wrapper {
    transition: background-color 0.3s ease;
}

.navbar-wrapper.scrolled {
    background-color: #ffffff;
    /* Add your desired background color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Optional shadow for depth */
}

.header {
    background-color: #f8f9fa;
    /* Bootstrap light gray */
    border-bottom: 1px solid #e7e7e7;
}

.logo img {
    max-height: 50px;
}

.navbar .nav-link {
    color: #343a40;
    /* Bootstrap dark gray */
    margin-right: 15px;
}

.navbar .nav-link.active {
    font-weight: bold;
    color: #007bff;
    /* Bootstrap primary color */
}