.testimonials-section {
    padding: 60px;
    text-align: center;
    background-color: #f9f9f9;

}

.testimonial-wrapper {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    margin: 0 auto;

}

#header-section {
    font-size: 2rem;
    color: #00468c;
    font-weight: 600;
}

.testimonial-slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.testimonial {
    flex: 0 0 100%;
    box-sizing: border-box;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    margin: 0;
    box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.1);
    transition: 0.5s;
}

.testimonial:hover {
    transform: scale(1.1);
}

.testimonial-photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
    box-shadow: 3px 3px 9px rgb(0, 0, 0);
    transition: 0.5s;
    cursor: pointer;
}

.testimonial-photo:hover {
    transition: 0.5s;
    transform: scale(1.1);
}

.testimonial-content {
    padding: 10px;
}

.testimonial-review {
    font-size: 1rem;
    font-style: italic;
    margin-bottom: 10px;
}

.testimonial-name {
    font-weight: bold;

}

.testimonial-position {
    color: #666;
}

.testimonial-dots {
    margin-top: 20px;
    text-align: center;
}

.dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #ccc;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dot.active {
    background-color: #00468c;
}


.testimonial-name {
    font-size: 1.25rem;
    color: #00468c;
    margin: 10px 0;
}

.testimonial-content #team-member-title {
    font-size: 0.8rem;
}



@media screen and (max-width:640px) {
    .testimonials-section {
        padding: 40px;
        text-align: center;
        background-color: #f9f9f9;
    }

}