/* Footer container styling */
.footer {
    padding: 60px 5%;
    background-color: #000000;
    color: #c2c2c2;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    z-index: 1000;
}

#footer {
    background-color: rgb(48, 47, 47);
}

#footer-header {
    font-size: 1.8rem;
}

/* Styling for sections inside the footer */
.footer .class,
.footer .classlist {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 250px;
}

.class {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: left;
    text-align: left;
    margin-left: 40px;
}

/* Section titles and text */
.footer h1 {
    margin-bottom: 15px;
    font-size: 2em;
    font-family: 'Cambria', serif;
    color: #fff;
}

.footer h3 {
    margin-bottom: 15px;
    font-size: 1.5em;
}

.footer p,
.footer li {
    font-size: 1em;
    margin-bottom: 10px;
}

/* Useful Links styling */
.classlist li {
    margin-bottom: 10px;
    list-style: none;
    cursor: pointer;
    transition: color 0.3s;
}

.classlist li:hover {
    color: #ffa500;
}

.useful-link ul {
    margin-left: -30px;
}

.useful-link ul li a {
    color: #a0a0a0;
    font-weight: 600;
}

.useful-link ul li a:hover {
    color: #ffa500;
}

/* Social Media icons and styling */
.social-media-content {
    display: flex;
    flex-direction: column;
}

.icons-social {
    display: flex;
    gap: 15px;
    margin-top: 10px;
}

.icons-social a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: #fff;
    align-items: center;
    transition: color 0.3s;
}

.icons-social .email-icon,
.icons-social .telegram-icon {
    padding: 10px;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.email-icon:hover {
    background-color: #941515;
}

.telegram-icon:hover {
    background-color: #0c1534;
}

.email-text,
.telegram-text {
    opacity: 0;
    font-weight: bold;
    transition: opacity 0.3s;
}

.email-icon:hover~.email-text,
.telegram-icon:hover~.telegram-text {
    opacity: 1;
}

/* Footer bottom section */

.last-footer {
    background-color: #111;
    color: #f0f0f0;
    padding: 20px 10%;
    text-align: center;
    border-top: 1px solid #cecccc;
    font-size: 1rem;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
}

.last-footer h5 {
    margin: 0;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.5px;
    font-size: 17px;
}

.last-footer h5 b {
    color: rgb(17, 17, 107);
    /* Highlight Click Addis in orange */
}

.last-footer:before {
    content: "";
    position: absolute;
    width: 150%;
    height: 5px;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    top: 0;
    left: -25%;
    animation: border-move 3s linear infinite;
}

@keyframes border-move {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}



#footer-useful-link {
    font-size: 1.2rem;
}



/* Responsive design adjustments */
@media screen and (max-width: 955px) {
    .footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .footer .classlist {
        margin-top: 20px;
    }
}

@media screen and (max-width:670px) {
    #footer-header {
        font-size: 1.7rem;
    }

}

@media screen and (max-width: 605px) {
    .last-footer {
        padding: 15px 5%;
        font-size: 0.9rem;
    }

    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .class {
        display: flex;
        flex-direction: column;
        justify-content: left;
        text-align: left;
        margin-left: 0px;

    }


    .useful-link ul {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: left;
        text-align: left;
        margin-left: -50px;
    }



    .social-media-content {
        margin-top: 0px;
        margin-bottom: -30px;
    }

    .social-media-content h3 {
        margin-bottom: 0px;
    }


    .useful-link {
        margin-bottom: -10px;
    }

    .class {
        margin-bottom: -20px;
    }

    #social-icons-react {
        font-size: 60px;
        background-color: red;
    }
}



@media screen and (max-width: 605px) {
    #footer-header {
        font-size: 1.6rem;
    }

    #footer-useful-link {
        font-size: 1.2rem;
    }

}

@media screen and (max-width:345px) {
    #footer-header {
        font-size: 1.4rem;
    }

    #footer-useful-link {
        font-size: 1rem;
    }

}