/* Blogs/Blog.css */

.span-detail {
    cursor: pointer;
    color: #0056b3;
    font-weight: 500;
}

.blog-detail {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 30px;
    font-family: 'Arial', sans-serif;
    overflow: hidden;
}


#blog-detail {
    width: 100%;
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 30px;
    font-family: 'Arial', sans-serif;
    overflow: hidden;
}

.blog-title {
    font-size: 1.4rem;
    margin-bottom: 5px;
    font-weight: 600;
    color: #000ba3;
    text-align: center;
    position: relative;
    display: inline-block;
    overflow: hidden;
    font-family: 'Times New Roman', Times, serif;

}

.blog-title::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
    background: #ff6b6b;
    transform: scaleX(0);
    transition: transform 0.5s ease;
    transform-origin: left;
}

.blog-title:hover::before {
    transform: scaleX(1);
}

.detail-detail-blog:hover .blog-title::before {
    transform: scaleX(1);
}

.blog-title span {
    display: inline-block;
    position: relative;
    animation: textAppear 0.5s ease-out forwards;
    opacity: 0;
}

@keyframes textAppear {
    from {
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.image-slider {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 20px;
    /* box-shadow: 0 4px 12px rgba(214, 215, 219, 0.2); */
}

.slider-wrapper {
    width: 100%;
    overflow: hidden;
}

.slider-images {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.slider-image {
    width: 100%;
    object-fit: cover;
    flex-shrink: 0;
}

.slider-img {
    object-fit: cover;
    width: 100%;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 1.8rem;
    border-radius: 50%;
    z-index: 10;
    transition: background-color 0.3s;
}

.arrow:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.left {
    left: 10px;
}

.right {
    right: 10px;
}

.dots {
    text-align: center;
    margin-top: 10px;
}

.dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: #bbb;
    border-radius: 50%;
    margin: 0 6px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dot.active {
    background-color: #333;
}

.blog-info {
    margin-bottom: 20px;
    text-align: center;
}

.blog-categories {
    font-size: 1rem;
    color: #666;
}

.blog-date {
    font-size: 1rem;
    color: #999;
    margin-top: 5px;
}

.blog-description {
    font-size: 1.15rem;
    line-height: 1.7;
    color: #444;
    /* background: #fafafa; */
    padding: 20px;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.back-container {
    padding: 10px;
    padding-left: 0px;
    color: rgb(60, 23, 163);
}

.back {
    padding: 5px;
    border-radius: 10px;
    padding-bottom: 10px;
    margin-bottom: 3px;
    font-weight: 600;
    color: rgb(60, 23, 163);
}

@media (max-width: 768px) {
    .blog-title {
        font-size: 1.5rem;
    }

    .arrow {
        font-size: 1.5rem;
        padding: 8px;
    }

    .dot {
        width: 10px;
        height: 10px;
        margin: 0 4px;
    }

    .blog-description {
        font-size: 1rem;
        padding: 15px;
    }

    .slider-image {
        height: 300px;
    }
}


@media (max-width: 700px) {
    #img-id {
        object-fit: cover;
    }

}

.slider-container {
    height: 500px;
}

.slider-img {
    object-fit: fill;
}

#img-id {
    height: 450px;
}







.bedroom-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.bedroom-button {
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    background-color: #f0f0f0;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.bedroom-button.active {
    background-color: #007bff;
    color: #fff;
}

.bedroom-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .bedroom-buttons {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .bedroom-button {
        width: 80%;
        margin-bottom: 50px;
    }

    .detail-detail-blog {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .blog-info {
        background-color: rgb(255, 255, 255);
    }

    .bedroom-button:hover {
        background-color: #0056b3;
        transform: scale(1);
    }
}