/* Blogs/BlogList.css */




.blog-list {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 35px;

}

#blog-list {
    display: flex;
    flex-direction: column;
}

.blog-list h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.blog-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.blog-card {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.blog-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

#blog-image {
    height: 250px;
}

.blog-content {
    padding: 15px;
    flex-grow: 1;
}

.blog-content h2 {
    font-size: 1.5rem;
    margin: 0 0 10px;
    color: #333;
}

.blog-content p {
    font-size: 1rem;
    color: #666;
    margin: 0 0 15px;
}

.read-more {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    margin-top: auto;
    transition: color 0.3s ease;
}

.read-more:hover {
    color: #0056b3;
}



@media screen and (max-width:768px) {
    .blog-image {
        width: 100%;
        height: 250px;
        object-fit: cover;
    }
}




/* Blogs/Blog.css */
.filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 15px;
}

.search-container {
    display: flex;
    align-items: center;
    text-align: center;
    position: relative;
    max-width: 250px;
    /* border: 2px solid #ddd; */
    border-radius: 25px;
    /* border-top-left-radius: 0px;
    border-bottom-right-radius: 0px; */
    /* background-color: #fff; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #e1e4fd;
    overflow: hidden;
    transition: all 0.3s ease;
}

.search-icon {
    color: #250061;
    font-size: 1.2rem;
    margin: 0 10px;
    cursor: pointer;
}

.search-input-container {
    display: flex;
    align-items: center;
    width: 0;
    overflow: hidden;
    transition: width 0.3s ease;
}

.search-input-container.visible {
    width: 250px;
}

.search-bar {
    border: none;
    border-left: 1px solid black;
    padding-bottom: 3px;
    padding-top: 7px;
    font-size: 1rem;
    width: 100%;
    margin-top: 0px;
    background-color: transparent;
}


.category-container {
    display: flex;
    align-items: center;
    border: 2px solid #ddd;
    border-radius: 25px;
    padding: 5px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.category-icon {
    color: #666;
    font-size: 1.2rem;
    margin-right: 10px;
}

.category-select {
    border: none;
    outline: none;
    padding: 10px;
    font-size: 1rem;
    background-color: #fff;
    flex-grow: 1;
}

@media (max-width: 768px) {
    .filters {
        flex-direction: flex;
    }

    .category-container {
        /* width: 100%; */
    }

    .search-container {
        display: flex;
        margin-left: 0;
        align-items: left;
        text-align: left;
        justify-content: left;
    }

}

@media (max-width: 768px) {
    .filters {
        flex-direction: column;
    }

    .category-container {
        width: 100%;
    }

}


#search-bar {
    margin: 0;
    padding: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}


/* Blogs/Blog.css */

.no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 200px;
    color: #666;
    font-size: 1.5rem;
    border-radius: 10px;
    margin-top: 20px;
    animation: fadeIn 0.5s ease-in-out;
}

.no-result-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.blog-card-no-result {
    display: flex;
    justify-content: center;
}

/* Animation for no-results message */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Style for the no-results text */
.no-results p {
    margin: 0;
}

























/* Blogs/BlogDetail.css */

.blog-detail {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    overflow: hidden;
}

.blog-detail h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
}

.image-slider {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 20px;
}

.slider-images {
    display: flex;
    transition: transform 0.5s ease;
    width: 100%;
}

.slider-image {
    width: 100%;
    height: auto;
    display: none;
}

.slider-image.active {
    display: block;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 1.5rem;
    border-radius: 50%;
    z-index: 10;
}

.left {
    left: 10px;
}

.right {
    right: 10px;
}

.dots {
    text-align: center;
    margin-top: 10px;
}

.dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #bbb;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
}

.dot.active {
    background-color: #333;
}

.blog-detail p {
    font-size: 1rem;
    color: #666;
}