.golden-color {
  font-weight: bold;
  /* Define the gradient as the background */
  background: linear-gradient(to right, #77530a, #ffd277, #77530a, #77530a, #ffd277, #77530a);
  /* Use the gradient background for text */
  -webkit-background-clip: text;
  /* For Webkit-based browsers */
  background-clip: text;
  /* Only show the background for text */
  -webkit-text-fill-color: transparent;
  /* Makes the text transparent to show the background */
  color: transparent;
}

.preserve-format {
  white-space: pre-wrap;
  /* Preserves spaces and line breaks */
  word-wrap: break-word;
  /* Ensures long words are wrapped */
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Webkit Browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px;
  /* Thin scrollbar */
}

::-webkit-scrollbar-thumb {
  background: #67698b;
  /* Base color */
  border-radius: 8px;
  /* Rounded corners */
  /* border: 2px solid #003366; Border for contrast */
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
  /* Smooth transition */
}

::-webkit-scrollbar-thumb:hover {
  background: #003366;
  /* Darker shade on hover */
  border-color: #002244;
  /* Darker border on hover */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* Glow effect */
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
  /* Light gray background */
  border-radius: 8px;
  /* Rounded corners */
}

/* Firefox */
scrollbar {
  width: 8px;
  /* Thin scrollbar */
}

scrollbar-thumb {
  background: #00468c;
  /* Base color */
  border-radius: 8px;
  /* Rounded corners */
  border: 2px solid #003366;
  /* Border for contrast */
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
  /* Smooth transition */
}

scrollbar-thumb:hover {
  background: #003366;
  /* Darker shade on hover */
  border-color: #002244;
  /* Darker border on hover */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* Glow effect */
}

scrollbar-track {
  background: #e0e0e0;
  /* Light gray background */
  border-radius: 8px;
  /* Rounded corners */
}

/* Smooth Scroll Animation */
@keyframes scrollAnimation {
  0% {
    background-color: #00468c;
  }

  50% {
    background-color: #003366;
  }

  100% {
    background-color: #00468c;
  }
}

::-webkit-scrollbar-thumb {
  animation: scrollAnimation 2s infinite;
  /* Animation for continuous effect */
}